<template>
  <div class="confirm flex-column">
    <!-- <headers></headers> -->
    <div class="confirm-wrapper manage-wrapper">
      <div class="confirm-main">
        <div>
          <div class="type">{{ workdetail.workname }}</div>
          <div class="item">作业主题：<span>{{this.$route.query.theme}}</span></div>
          <div class="item">
            问卷说明：
            <div style="display:inline-block" v-html="explain"></div>
          </div>
          <div class="item">
            提交时间：<span>{{ workdetail.beginTime }}-{{ workdetail.endTime }}</span>
          </div>
          <div class="item">积分：<span>100积分</span></div>
          <div class="item">
            能力标准：<span>{{powers}}</span>
          </div>
        </div>
        <div class="tc">
          <div class="btn" @click="begin">开始答题</div>
        </div>
      </div>
      <div class="popup_wrap" v-if="showPopup">
        <div class="popup">
          <div class="popup_top">
            <div class="title2">确认开始答题</div>
            <i class="el-icon-close" @click="close"></i>
            <!-- <img src="@/assets/close.png" @click="close" /> -->
          </div>

          <div class="content2">
            答题需一次性完成，中途退出将默认为交卷，交卷后无法在继续答题。确定是否开始？
          </div>
          <div class="button">
            <div class="cencel" @click="close">取消</div>
            <div class="affirm" @click="confirm">确认</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showPopup: false,
        workdetail: {},
        explain: "",
        powers: ''
      };
    },
    methods: {
      begin() {
        this.showPopup = true;
      },
      close() {
        this.showPopup = false;
      },
      confirm() {
        console.log("确认");
        let breadLength = this.$store.state.basics.breadList.length
        this.$router.push({
          path: "/answer",
          query: {
            questionId: this.$route.query.questionId,
            userType: "student",
            breadNumber: breadLength,
          },
        });
      },
      //获取问卷详情
      getDetail() {
        this.$Api.Form.getTeaForm(this.$route.query.questionId)
          .then((value) => {
            // console.log(value);
            this.workdetail = JSON.parse(value.data.title);
            let content = JSON.parse(value.data.content);
            // console.log(content)
            this.explain = content.filter(item => {
              return item.__vModel__ == "explain"
            })
            this.explain = this.explain[0].__config__.defaultValue
          })
          .catch((reason) => {
            console.log(reason);
          });
      },
      //获取该问卷能力标准
      getPower() {
        this.$Api.Form.getSecondPower(this.$route.query.questionId)
          .then((res) => {
            console.log(res);
            let str = "";
            res.data.map((item) => {
              return (str += item.title + ",");
            });
            this.powers = str.slice(0, str.length - 1);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
    mounted() {
      this.getDetail();
      this.getPower();
    },
  };
</script>

<style lang="less" scoped>
  .confirm {
    background: #fafafa;
    min-height: 100vh;

    .confirm-wrapper {
      flex: 1;
    }

    .confirm-main {
      margin: 80px auto 0px;
      background: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 0 47px 40px;

      .type {
        font-size: 13px;
        font-weight: bold;
        line-height: 49px;
        border-bottom: 1px solid #F5F4F4;
        margin-bottom: 15px;

        &::before {
          content: ' ';
          display: inline-block;
          width: 4px;
          height: 21px;
          background-color: #508EF9;
          margin-right: 11px;
          border-radius: 2px;
          vertical-align: -5px;
        }
      }

      .item {
        padding-left: 15px;
        color: #999999;
        font-size: 12px;
        line-height: 35px;
      }

      .btn {
        display: inline-block;
        background-color: #508EF9;
        padding: 0 42px;
        border-radius: 4px;
        text-align: center;
        line-height: 31px;
        color: #ffffff;
        font-size: 12px;
        margin: 20px auto;
        cursor: pointer;
      }
    }

    .popup_wrap {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.37);
      display: flex;
      align-items: center;

      .popup {
        margin: 0 auto;
        width: 400px;
        background: #ffffff;
        border-radius: 4px;

        .popup_top {
          background-image: url("../../assets/images/teach/create-title.png");
          background-size: cover;
          line-height: 37px;
          position: relative;

          .el-icon-close {
            font-size: 18px;
            position: absolute;
            color: #fff;
            top: 50%;
            right: 18px;
            transform: translateY(-50%);
            cursor: pointer;
          }

          .title2 {
            text-align: center;
            font-size: 17px;
            color: #fff;
            font-weight: 500;
          }
        }



        .content2 {
          padding: 25px 31px 45px;
          color: #666;
          line-height: 25px;
        }

        .button {
          padding: 0 50px;
          display: flex;
          align-items: center;
          justify-content: space-around;

          .cencel,
          .affirm {
            margin: 0 0 22px;
            border-radius: 5px;
            padding: 0 40px;
            line-height: 26px;
            font-size: 14px;
            color: #666;
            background: #fff;
            cursor: pointer;
          }

          .cencel {
            border: 1px solid #aaa;
          }

          .affirm {
            background: #508EF9;
            color: #ffffff;
          }
        }
      }
    }

    @media screen and (min-width:1250px) {

      .confirm-main {
        padding: 0 47px 40px;

        .type {
          font-size: 15px;
          line-height: 60px;
          margin-bottom: 20px;

          &::before {
            width: 5px;
            height: 25px;
            margin-right: 13px;
          }
        }

        .item {
          padding-left: 18px;
          font-size: 14px;
          line-height: 40px;
        }

        .btn {
          padding: 0 52px;
          line-height: 38px;
          font-size: 15px;
        }
      }

      .popup_wrap {
        .popup {
          width: 480px;

          .popup_top {
            line-height: 45px;

            .el-icon-close {
              font-size: 22px;
              right: 22px;
            }

            .title2 {
              font-size: 20px;
            }
          }

          .content2 {
            padding: 30px 38px 40px;
            line-height: 30px;
          }

          .button {
            padding: 0 70px;

            .cencel,
            .affirm {
              margin: 0 0 27px;
              padding: 0 50px;
              line-height: 32px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
</style>